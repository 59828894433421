/** Hook для определения мобильного отображения */
const useIsMobile = () => {
	/** Разрешение экрана мобильных устройства */
	const MIN_DESKTOP_RESOLUTION = 768;

	const isMobile = ref(false);

	/** @inheritDoc */
	onMounted(() => {
		isMobile.value = (MIN_DESKTOP_RESOLUTION > window.innerWidth);

		window.addEventListener('resize', () => {
			isMobile.value = (MIN_DESKTOP_RESOLUTION > window.innerWidth);
		})
	});

	return {
		isMobile,
	}
}

export default useIsMobile;

